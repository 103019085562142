<template>
<div>
      <v-dialog v-model="dialog" max-width="450px" persistent>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              <v-icon dark>
                mdi-plus
              </v-icon>
              Create
            </v-btn>
          </template>
          <v-card tile>
            <v-card-title class="primary white--text">
              <span class="text-h5"><v-icon large color="white" class="mr-2">{{formIcon}}</v-icon>{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row no-gutters>
                   <v-col cols="12" class="my-2 d-flex justify-center" v-if="selectedImage">
                    <v-img
                      contain
                      max-height="150"
                      :src="selectedImage"
                    ></v-img>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input
                      label="Logo"
                      outlined
                      dense
                      @change="getImage"
                      v-model="editedItem.party_logo"
                      prepend-icon=""
                      append-icon="mdi-image"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      dense
                      v-model="editedItem.party_name"
                      :error-messages="party_nameErrors"
                      required
                      @input="editedIndex == -1 ? $v.editedItem.party_name.$touch(): null"
                      @blur="editedIndex == -1 ? $v.editedItem.party_name.$touch(): null"
                      label="Name"
                      append-icon="mdi-rename-box"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-alert v-if="error" dense type="error">
                        {{error}}
                    </v-alert>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            
            <v-divider class="primary" />
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="error"  @click="close">
                Cancel
              </v-btn>
              <v-btn color="success"  @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>

       <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="error white--text"
              ><v-icon color="white" class="mr-2">mdi-delete</v-icon> You sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error"  @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="success"  @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
  <v-data-table
    :headers="headers"
    :items="data"
    sort-by="calories"
    class="elevation-3"
  >     
     <template v-slot:item.party_logo="{item}">
      <v-avatar  class="profile my-2" color="grey" size="80" tile>
        <v-img contain v-if="item.party_logo" :src="item.party_logo"></v-img>
      </v-avatar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2 warning--text" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-progress-circular
      v-if="loading"
      indeterminate
      color="secondary"
    ></v-progress-circular>
      <v-icon
         v-else
         x-large
         color="grey lighten-1"
         >
         mdi-tray-remove
      </v-icon>
      
    </template>
  </v-data-table>
</div>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
export default {
  name: 'Politicals',
  mixins: [validationMixin],
  data: () => ({
    headers: [
      {
        text: 'Logo',
        value: 'party_logo',
        sortable: false,
        align: 'start'
      },
      { text: 'Name', value: 'party_name' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    data: [],
    apiUrl: process.env.VUE_APP_API_URL,
    loading: true,
    dialog: false,
    dialogDelete: false,
    error: '',
    editedIndex: -1,
    editedItem: {
      party_name: '',
      party_logo: null,
    },
    defaultItem: {
      party_name: '',
      party_logo: null,
    },
    selectedImage: null,
  }),
  created() {
    this.getPoliticals();
  },
  methods: {
    editItem(item) {
      this.selectedImage = item.party_logo
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.party_logo = null
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.deletePolitical(this.editedItem.id)
      this.closeDelete();
    },
    close() {
      this.selectedImage = null
      this.dialog = false;
      this.$v.$reset()
      this.error = ''
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.editedIndex == -1 ? this.addPolitical() : this.updatePolitical() 
    },
    //Politicals API Calls
    getPoliticals() {
      this.loading = true
      this.$Axios
        .get('get-political-party')
        .then((res) => {
          this.data = res.data["AllParties"];
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
           this.loading = false
        })
    },
    getImage(){
        let formData = new FormData();
        formData.append('party_logo', this.editedItem.party_logo);
        this.$Axios({
        method: 'post',
        url: 'add-politicalparty-logo',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(({data}) => {
          this.selectedImage = data.logoImageUrl
        })
        .catch((e) => {
          console.log(e) 
        });
    },
    addPolitical() {
      
      this.error = ''
      this.$v.$touch() 
      console.log(this.$v)
      if(this.$v.$error){
        return
      }
      let data = {}
      data.party_name = this.editedItem.party_name
      data.party_logo = this.selectedImage
      this.$Axios.post('add-political-party',data)
        .then(() => {
          this.getPoliticals();
          this.close();
        })
        .catch((e) => {
          console.log(e) 
        });
    },
    updatePolitical() {
      this.error = ''
      this.$v.$touch()
      let data = {}
      if (this.editedItem.party_name) data.party_name = this.editedItem.party_name
      if (this.editedItem.party_logo) data.party_logo = this.selectedImage
      this.$Axios.post('update-political-party?id='  + this.editedItem.id, data)
        .then(() => {
          this.getPoliticals();
          this.close();
        })
        .catch((e) => {
          console.log(e)
        });
    },
    deletePolitical(id){
        this.$Axios
        .post('delete-political-party?id=' + id)
        .then(() => {
          this.getPoliticals();
        })
        .catch((e) => {
          console.log(e);
        })
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Political' : 'Edit Political';
    },
    formIcon() {
      return this.editedIndex === -1 ? 'mdi-plus' : 'mdi-edit';
    },
    filtredItems() {
      if (this.filter) return this.desserts.filter(item => item.name.includes(this.filter) || item.license.includes(this.filter) || item.email.includes(this.filter) || item.db.includes(this.filter) )
      else return this.desserts
    },
    //// Vuelidate Errors
    party_nameErrors () {
        const errors = []
        if (!this.$v.editedItem.party_name.$dirty) return errors
        !this.$v.editedItem.party_name.required && errors.push('Name is required !')
        return errors
    },
    party_logoErrors () {
        const errors = []
        if (!this.$v.editedItem.party_logo.$dirty) return errors
        !this.$v.editedItem.party_logo.required && errors.push('Logo is required !')
        return errors
      },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  validations: {
    editedItem: {
      party_name: {
        required
      },
      party_logo: {
        required,
      },
    },
  },
};
</script>